import React, { useState } from 'react';
import axios from 'axios';
import { ORDER } from '../../services/actions'
import { Button, Card, CardBody, CardFooter, Col, Container, Row } from 'react-bootstrap';
import RFG from '../../libs/inputs/RFG';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN } from "../../services/actions";
import { Check } from "@phosphor-icons/react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from '../public_payment/Footer';
import { Dialog } from 'primereact/dialog';


const Payment = () => {
    let dispatch = useDispatch()
    const { state } = useLocation();

    const navigate = useNavigate();
    let orderState = useSelector((state) => state?.orderState)
    const icons = {
        "bhim": 'https://th.bing.com/th?id=OSAAS.DBC88DCAA4D4B1072E05FD6F08034545&w=72&h=72&c=17&rs=1&o=6&pid=5.1',
        "gpay": 'https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo.png',
        "paytm": 'https://d1.awsstatic.com/Paytm-Logo.516dcbea24a48dc1f0187700fbd0f6a48f9a18c3.png',
        "phonepe": 'https://www.vhv.rs/dpng/d/411-4117619_phonepe-logo-png-phonepe-logo-transparent-background-png.png',
        "web": 'https://thumbs.dreamstime.com/b/qr-code-icon-vector-isolated-transparent-background-254411299.jpg',
        "default": 'https://arpitatulsyan.com/wp-content/uploads/2020/03/upi-logo-png-4.png',
    }

    return (
        <>
            {/* {JSON.stringify(state)} */}
            <Container fluid className="g-0">
                <Row className="d-flex justify-content-center g-0">
                    <Col xs={12} sm={8} md={5} xl={4}>
                        <Card style={{ minWidth: 210 ,minHeight:'100vh'}}>
                            <CardBody className="p-4">
                                <h1 className="mb-5 app-title">Payment Portal</h1>
                                <div>
                                    <h5><b>{state?.amount}</b> INR</h5>
                                    <h6>Loan Name {state?.loan_name}</h6>
                                    <h6>Amount will pay to <b>{state?.loan_number}</b> loan number</h6>
                                    <h6>Installment -<b> {state.installment} / 10</b></h6>
                                </div>
                                <Container fluid>
                                    <Row className="payment-icons mt-3">
                                        {
                                            orderState?.data?.data && Object.keys(orderState?.data?.data?.payload).map((key) => {
                                                let url = orderState?.data?.data?.payload[key]
                                                return <>
                                                    <Col xs={4} sm={4} md={4} xl={4} className="text-center">
                                                        <a href={url}>
                                                            <img src={icons[key]} style={{ width: 100, height: 60, objectFit: 'contain' }} />{key}
                                                        </a>
                                                    </Col>
                                                </>
                                            })
                                        }
                                    </Row>
                                </Container>
                            </CardBody>
                            <Footer />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Payment;
