import React from 'react';
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Badge } from 'primereact/badge';
import { Card, CardBody, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import Image from "../../libs/inputs/Image";
import { Avatar } from "primereact/avatar";
import { USER } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from 'primereact/inputtext';
import RFG from '../../libs/inputs/RFG';
import { useForm } from 'react-hook-form';

export default function Profile() {
    let dispatch = useDispatch()
    let user = useSelector((state) => state?.loginState.auth)
    let userState = useSelector((state) => state?.userState)
    const { handleSubmit, control, reset } = useForm({ defaultValues: userState?.data?.data, mode: "all" })
    const [avatar, setAvatar] = React.useState()

    React.useEffect(() => {
        dispatch({ type: `${USER}_GET`, payload: user?.id });
        console.log("USER", user)
    }, [])

    React.useEffect(() => {
        console.log("USER STATE", userState.data)
        reset(userState?.data?.data)
    }, [userState])

    const onSubmit = (data) => {
        if (!data['avatar'] || String(data['avatar']).startsWith("http")) {
            delete data['avatar']
        }
        console.log("Submiteed", data)
        dispatch({
            type: `${USER}_PATCH`, payload: data, callback: (resp) => {
                dispatch({ type: "LOGIN_AUTH", payload: resp, local: true })
            }
        });
    }

    return (
        <>
            <Container fluid className="py-3">
                <Row>
                    <Col sm={12} md={4} xl={4}>
                        <Card>
                            <CardBody>
                                {userState.loading && <Spinner />}

                                <div className="mb-3 text-center">
                                    <Image
                                        name="avatar"
                                        src={userState?.data?.data?.avatar}
                                        icon={<Avatar label="A" size="xlarge" shape="circle"  className="mb-3" />}
                                        onChange={(data) => { reset({...userState?.data?.data, avatar: data }) }}
                                        shape="circle"
                                    />
                                    <div>{user?.first_name} {user?.main_role}</div>
                                    <Badge value={`Main Role: ${user?.main_role ? user.main_role : "Not Updated"}`} size="small" severity="warning" />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <RFG name="avatar" control={control}>
                                        <input type="hidden" />
                                    </RFG>
                                    <RFG name="id" control={control}>
                                        <input type="hidden" />
                                    </RFG>
                                    <RFG name="first_name" label="First Name" control={control} rules={{ required: true }} className="mb-2">
                                        <InputText />
                                    </RFG>
                                    <RFG name="last_name" label="Last Name" control={control} rules={{ required: true }}>
                                        <InputText />
                                    </RFG>
                                    <RFG name="email" label="Email" control={control} rules={{ required: true }}>
                                        <InputText />
                                    </RFG>
                                    <RFG name="phone" label="Phone" control={control} rules={{ required: true }}>
                                        <InputText />
                                    </RFG>
                                    <input type="submit" className={'basic-btn'} />
                                </form>
                                {userState.message}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}