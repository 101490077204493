
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from "react-redux";
import * as ACTION from "../../services/actions";
import { Badge } from 'primereact/badge';
import Filter from '../../libs/inputs/Filter';

export default function Payments() {
    const dispatch = useDispatch()
    let paymentState = useSelector((state) => state?.allpaymentState)

    const [openDlg, setOpenDlg] = React.useState()

    useEffect(() => {
        dispatch({ type: `${ACTION.ALLPAYMENT}_GET`, payload: 0 });
        console.log("PAYMENTS:", paymentState)
    }, []);

    useEffect(() => {
        console.log("PAYMENTS:", paymentState)
    }, [paymentState?.data]);
    
    const resendForReciept = (order_id) => {
        dispatch({
            type: `${ACTION.RECIEPT}_GET`, payload: `reciept/post/${order_id}/`, callback: (res) => {
                dispatch({ type: `${ACTION.ALLPAYMENT}_GET`, payload: 0 });
            }
        });
    }
    const columns = [
        {
            field: 'created_at', header: 'Date',
            template: (row) => { return toLocale(row.created_at) }
        },
        {
            field: 'payment_status', header: 'Payment Status',
            template: (row) => { return <Badge style={{ fontSize: 8 }} value={row.payment_status} severity="success"></Badge> }
        },
        { field: 'payment_amount', header: 'Amount' },
        { field: 'order_id', header: 'Order ID' },
        { field: 'loan_number', header: 'Loan' },
        {
            field: 'reciept_no', header: 'Reciept',
            template: (row) => {
                if (row.reciept_no && row.reciept_no != "") {
                    return <Badge style={{ fontSize: 8 }} value={row.reciept_no} severity="success"></Badge>
                }
                return <Badge onClick={() => {
                    resendForReciept(row.order_id)
                 }} style={{ fontSize: 8, cursor: 'pointer' }} value={'Resent'} severity="danger"></Badge>
            }
        },
        // { field: 'report', header: 'Report' }
    ];
    const toLocale = (dateTime) => {
        return new Date(dateTime).toLocaleString("en-US", {})
    }

    return (
        <>
            <div className="card p-2 m-2">
                <div className='py-3 d-flex justify-content-between align-items-center'>
                    <div><h4>Payments</h4></div>
                    <div>
                        <Filter/>
                    </div>
                </div>
                {paymentState?.data?.results}
                <DataTable value={paymentState?.data?.data?.results} tableStyle={{ minWidth: '50rem' }}>
                    {columns.map((col, i) => {
                        return <Column key={col.field} field={col.field} header={col.header} body={col.template} />
                    })}
                </DataTable>
            </div>
        </>
    );
}
