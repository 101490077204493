import React, { useState } from 'react';
import axios from 'axios';
import { ORDER } from '../../services/actions'
import { Button, Card, CardBody, CardFooter, Col, Container, Row } from 'react-bootstrap';
import RFG from '../../libs/inputs/RFG';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN } from "../../services/actions";
import { Check } from "@phosphor-icons/react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from '../public_payment/Footer';
import { Dialog } from 'primereact/dialog';



const Order = () => {
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState('');
    let dispatch = useDispatch()
    const { state } = useLocation();

    const navigate = useNavigate();
    let orderState = useSelector((state) => state?.orderState)
    const [visible, setVisible] = useState(false);
    let user = JSON.parse(localStorage.getItem("data") || '{}')

    const { handleSubmit, control, reset, getValues } = useForm({
        mode: "all", defaultValues: { order_amount: 0, customer_phone: localStorage.mobile }
    })

    React.useEffect(() => {
        // reset({ order_amount: state?.amount })

    }, [orderState?.data])
    React.useEffect(() => {
        reset({
            ...getValues(), order_amount: state?.dueAmount,
            loan_number: state?.uniqueAccountNumber, 
            customer_phone: localStorage.mobile,
            category: state?.category,
        })
    }, [state])

    const handlePayment = async (data) => {
        setLoading(true);
        try {
            let payload = {
                order_id: state?.uniqueAccountNumber,
                ...data,
                customer_phone: user.cellNumber,
                customer_id: user.customerID,
                customer_name: user.customerName,
                branch_id: state?.branchID,
                module_code: state?.moduleCode,
            }
            
            dispatch({
                type: `${ORDER}_POST`, payload: payload, callback: (res) => {
                    navigate('/payment/', {
                        state: { res, ...state }
                    })
                }
            });

        } catch (error) {
            console.error('Payment error:', error);
        } finally {
            setLoading(false);
        }
    };
    const icons = {
        "bhim": 'https://th.bing.com/th?id=OSAAS.DBC88DCAA4D4B1072E05FD6F08034545&w=72&h=72&c=17&rs=1&o=6&pid=5.1',
        "gpay": 'https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo.png',
        "paytm": 'https://d1.awsstatic.com/Paytm-Logo.516dcbea24a48dc1f0187700fbd0f6a48f9a18c3.png',
        "phonepe": 'https://www.vhv.rs/dpng/d/411-4117619_phonepe-logo-png-phonepe-logo-transparent-background-png.png',
        "web": 'https://thumbs.dreamstime.com/b/qr-code-icon-vector-isolated-transparent-background-254411299.jpg',
        "default": 'https://arpitatulsyan.com/wp-content/uploads/2020/03/upi-logo-png-4.png',
    }

    return (
        <>
            {/* {JSON.stringify(user)} */}
            <Container fluid className="g-0">
                <Row className="d-flex justify-content-center g-0 h-100">
                    <Col xs={12} sm={8} md={5} xl={4}>
                        <Card style={{ minWidth: 210, minHeight: '100vh' }}>
                            <CardBody className="p-4" >
                                <h1 className="mb-5 app-title">Payment Portal</h1>
                                <div>
                                    <form autoComplete="false" onSubmit={handleSubmit((data) => {
                                        handlePayment(data)
                                    })}>
                                        <h5><b>{state?.dueAmount}</b> INR</h5>
                                        <h6>Loan Name {state?.loanType}</h6>
                                        <h6>Amount will pay to <b>{state?.uniqueAccountNumber}</b> loan number</h6>
                                        {state.installment && <h6>Installement -<b> {state.installment} / {state.totalInstallments}</b></h6>}
                                        <h6>Disbursed Amount -<b> {state.disbursedAmount} </b></h6>
                                        <p></p>
                                        <RFG name="order_amount" label="Amount" control={control} rules={{ required: true, pattern: { value: /^[0-9]+$/, message: "Only numbers are allowed" } }} className="mb-2">
                                            <InputText autoComplete="false" />
                                        </RFG>
                                        <RFG name="order_note" label="Note" control={control} className="mb-2">
                                            <InputText autoComplete="false" />
                                        </RFG>
                                        <div className="d-flex justify-content-center gap-4">
                                            <Button className="btn btn-danger px-4" onClick={() => { navigate('/dashboard/') }}>Cancel</Button>
                                            <Button className="btn btn-primary px-4" type="submit">Pay Amount</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={orderState.success ? "text-success" : "text-danger"}>{orderState?.message}</div>
                            </CardBody>
                            <Footer />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Order;
