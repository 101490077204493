
import React from "react"
export default function Footer({ icon, name }) {
 
  const comapny = () => {
    try {
      let main = JSON.parse(localStorage.getItem("settings") || '{}')
      return <>

        <div className="w-100 text-center box-footer pt-3 pb-3">
          <img src={icon ? icon : main?.icon} />
          <div className="mb-2" dangerouslySetInnerHTML={{ __html: (name ? name : main?.name) }}></div>
          <div className="d-flex justify-content-between gap-2 pt-4 px-3">
            <div className="notes2">@All Rights reserved</div>
            <div className="notes2">Powered by <span style={{textTransform:"italic"}}>icrats</span></div>
          </div>
        </div>

      </>
    } catch (error) {

    }
  }

  return (
    <>
     
      {comapny()}
    </>
  )
}