import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN, SETTINGS, LOANDUES } from "../../services/actions";
import { SignOut, Warning } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import React from "react";
import { ToggleButton } from 'primereact/togglebutton';
import Badge from 'react-bootstrap/Badge';
import depositsvg from './deposits.svg'
import termsvg from './term.svg'
import goldsvg from './gold.svg'

export default function Dashboard() {
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let loanState = useSelector((state) => state?.loanduesState)
  let userState = useSelector((state) => state?.login)
  const [loans, setLoans] = React.useState()
  const [selectedLoans, setSelectedLoans] = React.useState(null)
  let settingsState = useSelector((state) => state?.settingsState)
  // let main = JSON.parse(localStorage.getItem("settings") || '{}')
  const [toggle, setToggle] = React.useState(0)
  const { handleSubmit, control } = useForm({
    defaultValues: { "username": "admin@gmail.com", password: "Admin@123" },
    mode: "all"
  })
  let user = JSON.parse(localStorage.getItem("data") || '{}')
  React.useEffect(() => {
    try {
      dispatch({
        type: `${LOANDUES}_GET`, payload: user.customerID
      });

      dispatch({ type: `${SETTINGS}_GET`, payload: 1 })
    } catch (err) {

    }
  }, [])


  React.useEffect(() => {
    setLoans(loanState?.data?.data)
    console.log("LONAS:", loanState);
  }, [loanState?.data])

  React.useEffect(() => {
    let mainStr = settingsState?.data?.data?.main
    let main = typeof mainStr == 'string' ? JSON.parse(mainStr) : mainStr
    localStorage.setItem("settings", JSON.stringify(main))

  }, [settingsState])

  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleString();
    return localDate
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="app-border app-container" style={{ maxWidth: 400 }}>
          <div className="d-flex align-items-start flex-column" style={{ minWidth: 210, minHeight: '100vh' }}>
            <div className="mb-auto w-100 px-4">
              <div className="d-flex d-flex justify-content-between">
                <h1 className="mb-4 mt-3 app-title">Payment Portal</h1>
                <SignOut size={20} className="mt-4 text-danger cursor-pointer" onClick={() => {
                  localStorage.setItem("data", null)
                  navigate('/', { replace: true })
                }} />
              </div>
              <form autoComplete="false" onSubmit={handleSubmit((data) => {
                console.log("FORM", data)
                dispatch({ type: `${LOGIN}_AUTH`, payload: data });
              })}>
                <div className="my-2">
                  <p>Welcome  <b>{user?.customerName}</b></p>
                  <div className="my-2">
                    Please select the account from the
                    below list you want to make payment.
                  </div>
                </div>
                <h6 className="mt-5 app-title2 text-center">Accounts</h6>

                <div className="d-flex justify-content-center gap-3 my-3 mb-5">
                  <div className={"loan-box " + (toggle == 1 ? "loan-box-active" : "")} onClick={(e) => {
                    setSelectedLoans([...loans.installmentLoans])
                    setToggle(1)
                  }}
                  >
                    <img src={termsvg} width={60} />
                    <div>Term Loan</div>
                  </div>
                  <div className={"loan-box " + (toggle == 2 ? "loan-box-active" : "")} onClick={(e) => {
                    setSelectedLoans([...loans.nonInstallmentLoans])
                    setToggle(2)
                  }}
                  >
                    <img src={goldsvg} width={60} />
                    <div>Gold Loan</div>
                  </div>
                  <div className={"loan-box " + (toggle == 3 ? "loan-box-active" : "")} onClick={(e) => {
                    setSelectedLoans([...loans.recurringDeposits])
                    setToggle(3)
                  }}
                  >
                    <img src={depositsvg} width={60} />
                    <div>Deposit</div>
                  </div>

                </div>

              </form>
              {(Array.isArray(selectedLoans) && selectedLoans.length == 0) && <div className="text-center">No loans available</div>}
              {Array.isArray(selectedLoans) && selectedLoans.map((loan, i) => <Card key={i} style={{ borderRadius: 10 }} className="mb-2">

                <CardBody>
                  <div className="d-flex gap-2 align-items-center bg-light p-2" style={{borderRadius:6}}>
                    <div className="notes  w-100">{loan.loanType}</div>
                    <div className=" ">{loan.uniqueAccountNumber}</div>
                    <div className="notes"><b>{loan.disbursedAmount}</b>/-</div>
                  </div>
                  <div className="d-flex gap-2 align-items-center my-2">
                    <div className="notes  w-100">
                      <div>{toLocalTime(loan.startDate)}</div>
                      <div>{toLocalTime(loan.maturityDate)}</div>
                    </div>
                    <div className="notes"> </div>
                    <Badge style={{ width: 'auto' }} className="app-btn-primary mb-2">{loan.dueAmount}/-</Badge>
                  </div>
                  <div className="d-flex gap-2 align-items-center my-2">
                    <div className="notes  w-100">
                      <div>Total Installement : {loan.totalInstallments}</div>
                      <div>{}</div>
                    </div>
                    <div className="notes"> </div>
                    <Button className="mr-auto app-btn-primary" onClick={() => {
                        navigate('/order/', {
                          state: {
                            ...loan
                          }
                        })
                      }} style={{ width: 'auto' }}>Pay</Button>
                  </div>
                  
                </CardBody>
              </Card>)}

              <span className='text-primary cursor-pointer' onClick={() => {
                navigate('/history/', {
                  state: {
                    index: 2
                  }
                })
              }}> Transaction history</span>

            </div>
            <Footer />

          </div>
        </div>
      </div >

    </>
  )
}