import React, { cloneElement, Children } from 'react'
import { useForm, useController, UseControllerProps } from "react-hook-form"
export default function RFG(props) {
    const { children, label, register, name, required } = props;
    const { field, fieldState } = useController(props)
    return (
        <div>
            {Children.map(children, child => {
                return cloneElement(child, {
                    inputId: name,
                    placeholder: label,
                    ...field
                })
            })}

            <div className="error mb-3">
                {fieldState?.error?fieldState?.error?.message:null}
                {fieldState?.error?.type=='required'?`${name} field required`:null}
            </div>
        </div>
    )
}
