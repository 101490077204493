import { call, put } from 'redux-saga/effects';
import { getRequest, postRequest, patchRequest, delRequest, putRequest } from '../apis/api';
import Swal from 'sweetalert2'
const SUCCESS = "success"
const FAILED = "failed"
const SUCCESS_MESSAGE = "Successfully updated....."
const FAILED_MESSAGE = "Failed to update"
let REQUESTS = {}
export function isNumber(value) {
    if (typeof value === "string") {
        return !isNaN(value);
    }
    return !isNaN(value)
}
export const setRequest = (REQ) => {
    REQUESTS = REQ
}
const fire = async (type, message) => {
    Swal.close()
    let out = setTimeout(() => {
        console.error("Swal ", type)
        Swal.fire({
            title: "",
            text: message,
            timer: 2500
        }).then((value) => {
            clearTimeout(out)
        })
    }, 1000)
}

// ---  Should  not update  code----
let ACTION = (action) => {
    return action.replace(/_GET|_SEARCH|_POST|_PUT|_PATCH|_DELETE|_AUTH/gi, function (matched) {
        return "";
    }).trim();
}


export function* getUser({ type, payload, callback, local }) {
    type = ACTION(type)
    Swal.showLoading();
    if (local) {
        payload = payload ? payload : "IS_LOGGED"
        let msg = payload == "IS_LOGGED" ? null : "Successfully Updated"
        yield put({ "type": type + "_IS_LOGGED", "module": type, "data": payload, message: msg });
    } else if (Boolean(payload)) {
        let url = `${REQUESTS[type]["props"]["URL"]}/`
        try {

            const data = yield call(postRequest, url, payload);
            if (data.error) {
                yield put({ "type": type + "_FAILED", message: data.message });
            } else {
                yield put({ "type": type + "_SUCCESS", "data": data, });
            }
            Swal.close()
        } catch (error) {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
            Swal.close()
        }
    }
    Swal.close()
}

export function* _search({ type, payload, callback }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message
    if (props.loader) Swal.showLoading();
    try {
        const data = yield call(getRequest, payload);
        Swal.close();
        if (callback) callback(data)

    } catch (error) {
        if (callback) callback([])
        Swal.close();
    }
}
export function* _get({ type, payload, callback }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message
    if (props.loader) Swal.showLoading();
    let API_URL = `${REQUESTS[type]["props"]["URL"]}`
    let url = payload;
    if (isNumber(payload)) {
        payload = payload ? payload : 0;
        url = `${API_URL}/${payload}/`
    }
    console.log("request called", url)
    try {
        const data = yield call(getRequest, url);
        if (data.error) {
            yield put({ "type": type + "_FAILED", message: data.message });
            Swal.close()
            if (data?.status && props.loader) {
                fire(FAILED, data?.message)
            }
            if (callback) callback(data)
        } else if (data) {
            console.log("GET ", type)
            yield put({ "message": "", "type": type + "_SUCCESS", "data": data });
            Swal.close()
            if (callback) callback(data)
        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
            Swal.close()
            if (callback) callback(data)
        }
        Swal.close()
    } catch (error) {
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
        Swal.close()
    }
}
export function* _post({ type, payload, callback, cache }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message
    try {
        if (props.loading) Swal.showLoading();
        console.log("saveNote", payload)

        let url = `${REQUESTS[type]["props"]["URL"]}/`
        if (payload.hasOwnProperty("url")) {
            url = payload.url
            payload = payload.payload
        }
        const data = yield call(postRequest, url, payload);
        if (data.error) {
            if (callback) callback(data)
            console.log("error1:", JSON.stringify(data));
            if (props.loading) fire(FAILED, data?.error ? data.message : FAILED_MESSAGE)
            yield put({ "type": type + "_FAILED", message: data.message });
        } else if (data) {
            console.log("new data - ", data, " type -", type)
            yield put({ "type": type + "_SUCCESS", "data": data, "message": message ? message : "Successfully saved" });
            if (callback) callback(data)
            if (props.loading)
                fire(SUCCESS, message ? message : "Successfully saved")
        } else {
            console.log("error2:", JSON.stringify(data));
            yield put({ "type": type + "_FAILED", "message": message ? message : "Unknown Error" });
            if (props.loading)
                fire(FAILED, FAILED_MESSAGE)
        }
        if (callback) callback(data)
    } catch (error) {
        console.log("error3:", error.message);
        yield put({ "type": FAILED, "message": message ? message : "Unknown Error" });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, props.loading?.error ? props.loading?.error : FAILED_MESSAGE)
    }

}
export function* _put({ type, payload, callback, cache }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message
    if (props.loader) Swal.showLoading();
    let API_URL = `${props["URL"]}`
    try {
        console.log("putNote", API_URL, payload)
        const data = yield call(putRequest, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            console.log("error1", data)
            yield put({ "type": type + "_FAILED", message: data.message });
            if (props.loader)
                fire(FAILED, data.message)
        } else if (data) {
            yield put({ "type": type + "_SUCCESS", "data": data, "message": message ? message : "Successfully updated" });
            if (callback) callback(data)
            if (props.loader)
                fire(SUCCESS, SUCCESS_MESSAGE)
        } else {
            if (props.loader)
                fire(FAILED, FAILED_MESSAGE)
            yield put({ "type": type + "_FAILED", message: "Unknown Error" });
        }
        if (callback) callback({ error: true, message: "Unknown Error" })

    } catch (error) {
        console.log("exception", error)
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, FAILED_MESSAGE)
    }
}
export function* _patch({ type, payload, callback, cache }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message
    if (props.loader) Swal.showLoading();
    let API_URL = `${REQUESTS[type]["props"]["URL"]}`
    try {
        console.log("patchNote", payload)
        const data = yield call(patchRequest, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", message: data.message });
            if (props.loader)
                fire(FAILED, FAILED_MESSAGE)
        } else if (data) {
            yield put({ "type": type + "_SUCCESS", "data": data, "message": message ? message : "Successfully updated" });
            if (callback) callback(data)
            if (props.loader)
                fire(SUCCESS, SUCCESS_MESSAGE)

        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
            if (props.loader)
                fire(FAILED, FAILED_MESSAGE)
        }
        if (callback) callback(data)
    } catch (error) {
        console.log("Error", error)
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
        // fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
    }

}
export function* _del({ type, payload, callback, cache }) {
    type = ACTION(type)
    let props = REQUESTS[type]["props"]
    let message = props?.message

    if (props.loader) Swal.showLoading();

    let API_URL = `${REQUESTS[type]["props"]["URL"]}/`
    try {
        console.log("delete", payload)

        const data = yield call(delRequest, `${API_URL}`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", message: data.message });
            if (props.loader)
                fire(FAILED, data.message)
        } else if (data) {
            console.log("deleted data - ", data)
            yield put({ "type": type + "_SUCCESS", "data": data, "message": message ? message : "Successfully deleted" });
            if (props.loader)
                fire(SUCCESS, SUCCESS_MESSAGE)
        } else {
            yield put({ "type": type + "_FAILED", message: "Unknown Error" });
            if (props.loader)
                fire(FAILED, FAILED_MESSAGE)
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": type + "_FAILED", message: "Unknown Error" });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, FAILED_MESSAGE)
    }
}

// ---  Should  not update above code----