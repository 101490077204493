import React from "react";
import Webcam from "react-webcam";
import { Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const WebcamCapture = ({ init, onChange, open }) => {
    const webcamRef = React.useRef(null);
    const [visible, setVisible] = React.useState(open);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            onChange(imageSrc)
        },
        [webcamRef]
    );
    React.useEffect(() => {
        if (webcamRef.current && init) {
            init(webcamRef)
        }
    }, [webcamRef])
    React.useEffect(() => {
        setVisible(open)
    }, [open])

    const headerElement = (
        <div>


        </div>
    );

    const footerContent = (
        <div>
            <Button size="small" onClick={() => { setVisible(false); capture() }} autoFocus >OK</Button>
        </div>
    );

    return (
        <div>
            <Dialog visible={visible} header={headerElement} footer={footerContent} onHide={() => { if (!visible) return; setVisible(false); onChange(null) }}>
                <Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={200}
                    videoConstraints={videoConstraints}
                />
            </Dialog>
            {/* <button onClick={capture}>Capture photo</button> */}
        </div>
    );
};

export default WebcamCapture