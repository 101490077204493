
import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { List, SignOut, UserCircle,Gear, Bell, EnvelopeSimple } from "@phosphor-icons/react";
import { PrimeIcons } from 'primereact/api';
import { useNavigate } from "react-router-dom";

export default function MyToolBar({ user, onToggled, onLogout, appName, logo }) {
    let menuRight = React.useRef(null);
    const navigate = useNavigate()
    const itemRenderer = (item) => (
        <a className="flex align-items-center p-menuitem-link p-2">
            <span>{item.icon}</span>
            <span className="mx-2">{item.label}</span>
        </a>
    );
    const items = [
        {
            label: user?.first_name
        },
        {
            label: "Profile",
            icon: <UserCircle fontSize={"1.5em"} />,
            command: () => {
                navigate("/profile/")
            }
        },
        {
            label: "Settings",
            icon: <Gear fontSize={"1.5em"} />,
            command: () => {
                navigate("/settings/")
            }
        },
        {
            label: "Logout",
            icon: <SignOut fontSize={"1.5em"} />,
            command: () => {
                if (onLogout) onLogout()
            }
        },
    ];

    const start = <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" height="40" className="mr-2"></img>;
    const end = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
        </div>
    );

    return (
        <div className="d-flex align-items-center ps-toolbar w-100 py-3">
            <div><List size={24} style={{'cursor':'pointer'}} onClick={() => onToggled()} /></div>
            <div className="px-2 d-flex align-items-center">
                <img src={logo} />
                <div className="text-left" style={{minWidth:180}}> {appName} </div>
            </div>
            <div className="w-100 d-flex justify-content-end px-2">
                <Bell style={{ fontSize: '1.5rem' }} ><Badge value="2" /></Bell>
                <EnvelopeSimple className='p-overlay-badge' style={{ fontSize: '1.5rem' }}><Badge severity="danger"></Badge></EnvelopeSimple>
            </div>
            <div>
               
                <Menu model={items} popup ref={menuRight} popupAlignment="right" />
                <Avatar label={user?.first_name?.slice(0, 2)?.toUpperCase()}
                    size="small" shape="circle" image={user?.avatar}
                    onClick={(event) => menuRight.current.toggle(event)} />
            </div>
        </div>
    )
}
