
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN, SETTINGS, DASHBOARD, USER } from "../../services/actions";
import { SignOut, Tag, Warning } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import React from "react";
import StackBar from "./StackBar";
import { Knob } from 'primereact/knob';
import { DataScroller } from 'primereact/datascroller';
import { Menubar } from 'primereact/menubar';
import { Toast } from 'primereact/toast';


export default function Dashboard() {
  const documentStyle = getComputedStyle(document.documentElement);
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let dashboardState = useSelector((state) => state?.dashboardState)
  let usersState = useSelector((state) => state?.userState)
  let userState = useSelector((state) => state?.login)

  React.useEffect(() => {
    try {
      dispatch({ type: `${SETTINGS}_GET`, payload: 1 })
      dispatch({ type: `${DASHBOARD}_GET`, payload: 0 })
      dispatch({ type: `${USER}_GET`, payload: 0 })
    } catch (err) {
    }
  }, [])
  React.useEffect(() => {
    console.log("DATA", dashboardState?.data?.data)
  }, [dashboardState?.data?.data])

  React.useEffect(() => {
    console.log("USERS", usersState?.data?.data)
  }, [usersState?.data?.data])

  const itemTemplate = (data) => {
    return (
      <div className="col-12">
        <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">

          <img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" width={60} src={`${data.avatar}`} alt={data.first_name} />
          <div className="flex flex-column lg:flex-row justify-content-between align-items-center xl:align-items-start lg:flex-1 gap-4">
            <div className="flex flex-column align-items-center lg:align-items-start gap-3">
              <div className="flex flex-column gap-1">
                <div className="text-2xl font-bold text-900">{data.first_name}</div>
                <div className="text-700">{data.last_name}</div>
              </div>
              <div className="flex flex-column gap-2">
                <span className="flex align-items-center gap-2">
                  <i className="pi pi-tag product-category-icon"></i>
                  <span className="font-semibold">{data.main_role}</span>
                </span>
              </div>
            </div>
            <div className="flex flex-row lg:flex-column align-items-center lg:align-items-end gap-4 lg:gap-2">
              <span className="text-2xl font-semibold">${data.price}</span>
              <Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>
              <Tag value={data.main_role} ></Tag>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function MenuToolBar() {
    const toast = React.useRef(null);
    const items = [
      {
        label: 'File',
        icon: 'pi pi-file',
        items: [
          {
            label: 'New',
            icon: 'pi pi-plus',
            command: () => {
              toast.current.show({ severity: 'success', summary: 'Success', detail: 'File created', life: 3000 });
            }
          },
          {
            label: 'Print',
            icon: 'pi pi-print',
            command: () => {
              toast.current.show({ severity: 'error', summary: 'Error', detail: 'No printer connected', life: 3000 });
            }
          }
        ]
      },
      {
        label: 'Search',
        icon: 'pi pi-search',
        command: () => {
          toast.current.show({ severity: 'warn', summary: 'Search Results', detail: 'No results found', life: 3000 });
        }
      },
      {
        separator: true
      },
      {
        label: 'Sync',
        icon: 'pi pi-cloud',
        items: [
          {
            label: 'Import',
            icon: 'pi pi-cloud-download',
            command: () => {
              toast.current.show({ severity: 'info', summary: 'Downloads', detail: 'Downloaded from cloud', life: 3000 });
            }
          },
          {
            label: 'Export',
            icon: 'pi pi-cloud-upload',
            command: () => {
              toast.current.show({ severity: 'info', summary: 'Shared', detail: 'Exported to cloud', life: 3000 });
            }
          }
        ]
      }
    ];

    return (
      <div className="card">
        <Menubar model={items} />
        <Toast ref={toast} />
      </div>
    )
  }


  function toLocalTime(utcDateString) {
    return new Date(utcDateString).toLocaleString()
  }

  const colors = ['--blue-500','--green-500','--yellow-500','--red-500']

  return (
    <>
      <Container fluid className="py-3">
        <Row >
          {Array.isArray(dashboardState?.data?.data?.results) && dashboardState?.data?.data?.results?.map((v,i) => {
            let c = ""; try{c = colors[i];}catch(err){c = colors[0]}
            let color = documentStyle.getPropertyValue(c)
            return <Col className="py-3">
              <Card role="region" className="p-2" style={{
                border: ('solid ' + color),
                borderWidth: '0 0 0 6px',
                color: '#696cff'
              }}>
                <Knob value={v.count} min={0} size={50} strokeWidth={16} readOnly />
                {v.name}
              </Card>
            </Col>
          })}
        </Row>
        <Row>
          <Col md={4} className="py-3">
            <Card role="region" className="p-2">
              <DataScroller value={usersState?.data?.data?.results} itemTemplate={itemTemplate}
                rows={5} inline scrollHeight="500px"
                header="Users List" />
            </Card>
          </Col>
          <Col md={8}>

            <Row>
              <Col className="pt-3" md={12}>
                <MenuToolBar />
              </Col>
              {[1, 2, 3].map(() => {
                return <Col className="py-3" md={6}>
                  <StackBar />
                </Col>
              })}
            </Row>
          </Col>

        </Row>

      </Container >

    </>
  )
}