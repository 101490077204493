import React from 'react';
import { ROLE, USERROLE, USER } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { ListBox } from 'primereact/listbox';
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import RFG from '../../libs/inputs/RFG';
import { InputText } from 'primereact/inputtext';
import { Cube, Pencil, Trash, Plus, Key, Ticket, CheckSquare, XSquare, ToggleLeft } from "@phosphor-icons/react";
import { Dialog } from 'primereact/dialog';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { ToggleButton } from "primereact/togglebutton";
import Badge from 'react-bootstrap/Badge';
import Swal from 'sweetalert2';
import { AutoComplete } from "primereact/autocomplete";
import SearchableList from '../../libs/list/SearchableList';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';

export default function UserRole() {
    let dispatch = useDispatch()
    let roleState = useSelector((state) => state?.roleState)
    let userState = useSelector((state) => state?.userState)

    const [selectedRole, setSelectedRole] = React.useState()
    const [selectedUser, setSelectedUser] = React.useState()
    const [openDlg, setOpenDlg] = React.useState()
    const [openPemissionDlg, setOpenPemissionDlg] = React.useState([])

    const { handleSubmit, control, reset } = useForm({ defaultValues: {}, mode: "all" })

    React.useEffect(() => {
        dispatch({ type: `${ROLE}_GET`, payload: 0 });
        dispatch({ type: `${USER}_GET`, payload: 0 });
    }, [])

    React.useEffect(() => {
    }, [roleState?.data?.data])

    React.useEffect(() => {
        console.log("user data", userState?.data?.data)
    }, [userState?.data?.data])

    const confirm2 = (event, data) => {
        Swal.fire({
            title: "Do you want to delete?",
            showDenyButton: true,
            confirmButtonText: "Cancel",
            denyButtonText: `Delete`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isDenied) {
                onDelete(data)

            }
        })
    };

    const onSubmit = (data) => {
        let action = data.id ? `${ROLE}_PATCH` : `${ROLE}_POST`
        dispatch({
            type: action, payload: data, callback: (resp) => {
                dispatch({ type: `${ROLE}_GET`, payload: 0 });
            }
        });
    }

    const onDelete = (data) => {
        let action = data.id ? `${ROLE}_DELETE` : `${ROLE}_POST`
        dispatch({
            type: action, payload: data.id, callback: (resp) => {
                dispatch({ type: `${ROLE}_GET`, payload: 0 });
            }
        });
    }

    const formatData = (role) => {
        setSelectedRole(role.name)
        let assigned = role._assigned
        let all = role?._permission ? Object.keys(role?._permission).map((key) => {

            let access = { role: role.name, value: key, name: key }
            for (let _access of role?._permission[key]) {
                let per = _access.codename.split("_")[0]
                access[per] = _access.codename
                access[`${per}_assigned`] = false
                if (assigned[key]) {
                    for (let _assigned of assigned[key]) {
                        console.log("access", access)
                        if (_access.codename == _assigned.codename) {
                            access[`${per}_assigned`] = true
                            break
                        }

                    }
                }
            }

            return access
        }) : []

        setOpenPemissionDlg(all)
        return all
    }

    const header = () => <>
        <hr></hr>
        <div className="d-flex gap-2 align-items-center flex-wrapper w-100 mt-2">
            <div className="flex-grow-1">Permissions</div>
            <div className="d-flex gap-2 align-items-center">
                Add
            </div>
            <div className="d-flex gap-2 align-items-center">
                View
            </div>
            <div className="d-flex gap-2 align-items-center">
                Edit
            </div>
            <div className="d-flex gap-2 align-items-center">
                Delete
            </div>
        </div>
    </>

    const assignRoleToUser = (action) => {
        let data = {
            id: selectedUser?.id,
            groups: selectedRole.map(role => role.name),
            // action: action ? "" : "remove"
        }
        dispatch({
            type: `${USERROLE}_PATCH`, payload: data, callback: (resp) => {
                dispatch({ type: `${USER}_GET`, payload: 0 });
            }
        });
    }

    const itemTemplate = (option, buttons) => {
        return (
            <div className="d-flex gap-2 align-items-center flex-wrapper w-100">
                <div><Cube fontSize={20} color="blue" weight='fill' /></div>
                <div className="flex-grow-1">{option.first_name}</div>

                <div className="d-flex gap-2 align-items-center flex-wrap">
                    {option.group_list.map(group => <>
                        <Badge bg={option.delete_assigned ? 'success' : 'danger'}
                        >
                            {group.name}
                        </Badge>
                    </>)}
                </div>
                <div>
                    <Key fontSize={20} color="orange" onClick={() => { setSelectedUser(option); setOpenDlg(true) }} />
                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog header={"Assign Roles to " + selectedUser?.first_name} style={{ width: '50vw', maxWidth: "400px", marginLeft: 20, marginRight: 20 }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                visible={openDlg} onHide={() => { setOpenDlg(false) }}>
                <MultiSelect
                    value={selectedRole} onChange={(e) => { console.log(e.value); setSelectedRole(e.value) }}
                    options={roleState?.data?.data?.results} optionLabel="name"
                    placeholder="Select Roles" maxSelectedLabels={3} className="w-full md:w-20rem" />
                {/* <div className="d-flex justify-content-center flex-wrap gap-2 mt-2">
                    <SelectButton value={selectedRole} onChange={(e) => { console.log(e.value); setSelectedRole(e.value) }}
                        optionLabel="name" options={roleState?.data?.data?.results} multiple />
                </div> */}
                <Button className="mt-2" onClick={() => { assignRoleToUser() }}>Assign</Button>

            </Dialog>
            <Container>
                <Row>
                    <Col xs={12} sm={10} md={5} className="my-3">
                        <SearchableList
                            onClick={(i, data) => { setSelectedUser(data); setOpenDlg(true) }}
                            buttons={[
                                <Key fontSize={20} color="orange" />
                            ]}
                            headerButtons={[<Plus fontSize={20} color="orange" onClick={() => { setOpenDlg(true) }} />]}
                            optionLabel="first_name"
                            list={userState?.data?.data?.results}
                            onRowRender={itemTemplate}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}