import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN } from "../../services/actions";
import { Warning } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

export default function Warnings() {
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let userState = useSelector((state) => state?.login)
  const { handleSubmit, control } = useForm({
    defaultValues: { "username": "admin@gmail.com", password: "Admin@123" },
    mode: "all"
  })

  return (
    <>

      <Container fluid className="g-0">
        <Row className="d-flex justify-content-center g-0">
          <Col xs={12} sm={8} md={5} xl={4}>
            <Card style={{ minWidth: 210, height: '100vh' }}>
              <CardBody  className="px-4">
                <h1 className="mb-5">Payment Portal</h1>
                <form autoComplete="false" onSubmit={handleSubmit((data) => {
                  console.log("FORM", data)
                  dispatch({ type: `${LOGIN}_AUTH`, payload: data });
                })}>
                  <div className="text-center text-danger my-4">
                    <Warning fontSize={80} />
                    <div className="my-3">
                      This Mobile Number is not registered
                      with us, or you do not have an active
                      account.
                    </div>
                  </div>

                  <div className="d-flex justify-content-center gap-4 pt-4">
                    <Button className="btn app-btn-primary px-4" onClick={() => { navigate("/") }} type="submit">Back</Button>
                    <Button className="btn app-btn-danger px-4" onClick={()=>{window.close ()}}>Cancel</Button>
                  </div>
                </form>
                <div className="error">{userState?.message}</div>

              </CardBody>
              <Footer />
            </Card>

          </Col>
        </Row>
      </Container>

    </>
  )
}