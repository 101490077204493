import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { PAYMENT } from "../../services/actions";
import { CalendarDots, Clock, CurrencyInr, Warning, X } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import React from "react";
import { ToggleButton } from 'primereact/togglebutton';
import Badge from 'react-bootstrap/Badge';
import depositsvg from './deposits.svg'
import termsvg from './term.svg'
import goldsvg from './gold.svg'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function History() {
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let paymentState = useSelector((state) => state?.paymentState)
  let user = JSON.parse(localStorage.getItem("data") || '{}')
  const [history, setHistory] = React.useState([])

  React.useEffect(() => {
    try {
      dispatch({
        type: `${PAYMENT}_GET`, payload: `payments/0/?customer_number=${user.cellNumber}`
      });
    } catch (err) {

    }
  }, [])

  React.useEffect(() => {
    const group = {}
    paymentState?.data?.data?.results.map((row, i) => {
      let d = new Date(row.created_at)
      let options = { 'year': "numeric", 'month': 'short' }
      let ym = d.toLocaleString("en-US", options);
      row.day = d.toLocaleString("en-US", { 'day': '2-digit' });
      row.time = d.toLocaleString("en-US", { 'hour': '2-digit', 'minute': '2-digit', hour12: false });
      let groups = group[ym] ? group[ym].push(row) : group[ym] = [row];
    })
    setHistory(group)
    console.log("History", group)
  }, [paymentState?.data])

  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);
    // Convert the UTC date to local time
    const localDate = utcDate.toLocaleString();
    return localDate
  }

  return (
    <>

      <div className="app-border app-container m-auto" style={{ maxWidth: 400 }}>
        <div className="d-flex align-items-start flex-column" style={{ minWidth: 210, minHeight: '100vh' }}>
          <div className="mb-auto w-100 px-3">
            <div className="d-flex d-flex justify-content-between pt-3">
              <h1 className="mb-5 app-title">Payment Portal</h1>
              <X className="cursor-pointer text-danger" size={20} onClick={() => { navigate('/dashboard/') }} />
            </div>
            {(Array.isArray(paymentState?.data?.data?.results) && paymentState.data.data.results.length == 0) && <div className="text-center">No loans available</div>}


            {Object.keys(history)?.map((key, i) => {
              return <>
                <div className='pt-3 w-100'>
                  <h6 className="">{key}</h6>
                  {
                    history[key]?.map((row, k) => {
                      let STATUS = {
                        "PENDING":{"text":"Pending", _class:"text-warning"},
                        "SUCCESS":{"text":"Success", _class:"text-success"},
                        "USER_DROPPED":{"text":"User dropped", _class:"text-primary"},
                        "FAILED":{"text":"Failed", _class:"text-danger"},
                      }
                      let _class = STATUS[row.payment_status]?._class
                      let text = STATUS[row.payment_status]?.text?STATUS[row.payment_status]?.text:row.payment_status;
                      return (
                        <table width={'100%'} key={k} className="table-history">
                          <tbody key={"row" + k}>
                            <tr>
                              <td className="py-3 text-center">
                                <CalendarDots size={24} />
                                <div className="paragraph">
                                  {row.day}
                                </div>

                              </td>
                              <td style={{ width: '100%' }} className="notes px-2">
                                <div className="paragraph">{row?.loan_number}</div>
                                <div>{row.customer_note}</div>
                              </td>
                              <td className="px-2">
                                <div className="d-flex gap-1 align-items-start justify-content-end notes mb-2"><Clock size={18} />{row?.time}</div>
                                <b><div className={"d-flex gap-1 align-items-center " + _class}><CurrencyInr size={14} />{row.payment_amount}</div></b>
                              </td>
                              <td className="text-primary">
                                <div style={{ fontSize: 14}} className={"mb-2 " + _class}>{text}</div>
                                <a traget="_blank" href={`https://pay.zcore.in/api/redirect/${row?.order_id}/?from=history`}>Reciept</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )
                    })
                  }
                </div>
              </>
            })}

          </div>
          <Footer />
        </div>
      </div>


    </>
  )
}