import { Horse, Heart, Cube, WindowsLogo, Trash, Key } from "@phosphor-icons/react";

import Profile from "../pages/auth/Profile"
import CheckInOut from "../pages/punching/CheckInOut"
import Role from "../pages/permissions/Role"
import Login from "../pages/auth/Login"
import Home from "../pages/public_payment/Home";
import OTP from "../pages/public_payment/OTP";
import Warnings from "../pages/public_payment/Warnings";
import CashFreeWarnings from "../pages/cashfree/Warnings";
import PaymentDashboard from "../pages/public_payment/Dashboard";
import Order from "../pages/cashfree/Order";
import Payment from "../pages/cashfree/Payment";
import Settings from "../pages/auth/Settings";
import SearchableList from "../libs/list/SearchableList"
import TermsConditiions from "../pages/public_payment/TermsConditiions";
import UserRole from "../pages/permissions/UserRole";
import Users from "../pages/users/Users";
import History from "../pages/public_payment/History";


export const anonyMenus = [
    {
        to: "/",
        // component: <Order />,
        component: <Home />,
    },
    {
        to: "/login/",
        component: <Login />,
    },
    {
        to: "/otp/",
        component: <OTP />,
    },
    {
        to: "/warning/",
        component: <Warnings />,
    },
    {
        to: "payment/warning/",
        component: <CashFreeWarnings />,
    },
    {
        to: "/order/",
        component: <Order />,
    },
    {
        to: "/payment/",
        component: <Payment />,
    },
    {
        to: "/history/",
        component: <History />,
    },
    {
        to: "/dashboard/",
        component: <PaymentDashboard />,
    },
    {
        to: "/termsconditions/",
        component: <TermsConditiions />,
    },

]

export const menus = [
    {
        icon: <WindowsLogo />,
        label: "Check In",
        to: "/",
        component: <CheckInOut />,
        hide: true
    },
    {
        icon: <WindowsLogo />,
        label: "Check In",
        to: "/dashbord/",
        component: <CheckInOut />,
        permission: "*"
    },
    {
        icon: <WindowsLogo />,
        label: "Profile",
        to: "/profile/",
        component: <Profile />,
        hide: true
    },
    {
        icon: <WindowsLogo />,
        label: "Settings",
        to: "/settings/",
        component: <Settings />,
        hide: true,
        permission: "*",
    },

    {
        icon: <Horse />,
        label: "Permissions",
        to: "/profile/",
        component: <Profile />,
        permission: "permission",
        submenus: [
            {
                icon: <Heart />,
                label: "Role",
                to: "/role/",
                component: <Role />,
                permission: "permission",
            },
            {
                icon: <Cube />,
                label: "Assign User To Role",
                to: "/user/role/",
                component: <UserRole />,
                permission: "permission",
            },

        ]
    },
    {
        icon: <Horse />,
        label: "User Management",
        to: "/users/",
        component: <Profile />,
        permission: "permission",
        submenus: [
            {
                icon: <Heart />,
                label: "User",
                to: "/user/",
                component: <Users />,
                permission: "user",
            },
        ]
    }

]