import { createAction } from '../libs/redux/saga'
export const ISLOGGED = "ISLOGGED";
export const FORGOT = "FORGOT";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
//---------------------------------
export const ROLE = "ROLE";
export const USERROLE = "USERROLE";
export const PERMISSION = "PERMISSION";
export const SETTINGS = "SETTINGS";
export const USER = "USER";
export const ORDER = "ORDER";
export const LOAN_NUMBER = "LOAN_NUMBER";
export const LOANDUES = "LOANDUES";
export const PAYMENT = "PAYMENT";

export const REQUESTS = {
    ...createAction(LOGIN, "token", { loader: true }),
    ...createAction(LOGOUT, "token", {}),
    ...createAction(ISLOGGED, "token", {}),
    // ---  Do not update above code----
    ...createAction(ROLE, "group", {}),
    ...createAction(USERROLE, "user/group", {}),
    ...createAction(PERMISSION, "permission", {}),
    ...createAction(SETTINGS, "settings", {}),
    ...createAction(FORGOT, "forgotpassword", {}),
    ...createAction(USER, "user", {}),
    ...createAction(ORDER, "orders", {}),
    ...createAction(LOAN_NUMBER, "loan/number", {}),
    ...createAction(LOANDUES, "loan/dues", {}),
    ...createAction(PAYMENT, "payments", {}),
}