import React from 'react'
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN, SETTINGS, LOAN_NUMBER } from "../../services/actions";
import { CheckSquare, Square } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Footer from './Footer';
export default function Home() {
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let [checked, setChecked] = React.useState(false)
  let userState = useSelector((state) => state?.login)
  let settingsState = useSelector((state) => state?.settingsState)
  const [main, setMain] = React.useState()
  const { form, getFieldState, handleSubmit, control, setError, getValues, reset } = useForm({
    defaultValues: {}, //'mobile': '9947774499' 
    mode: "all"
  })

  React.useEffect(() => {
    loadCaptchaEnginge(4, 'black', 'white');

    dispatch({ type: `${SETTINGS}_GET`, payload: 1 })

  }, [])

  React.useEffect(() => {
    let mainStr = settingsState?.data?.data?.main
    let main = typeof mainStr == 'string' ? JSON.parse(mainStr) : mainStr
    setMain(main)
    localStorage.setItem("settings", JSON.stringify(main))

  }, [settingsState])

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data")||'{}')
    if(data?.cellNumber){
      console.log("DATA - ",data)
      navigate("/dashboard/",{replace:true})
    }
    
  }, [localStorage.getItem("data")])

  return (
    <>

      <Container fluid className="g-0">
        <Row className="d-flex justify-content-center g-0">
          <Col xs={12} sm={8} md={5} xl={4}>
            <Card style={{ minWidth: 210,minHeight:'100vh' }}>
              <CardBody className="px-4">
                <h1 className="mb-4 app-title">Payment Portal</h1>
                <form autoComplete="false" onSubmit={handleSubmit((data) => {
                  console.log("FORM", data)
                  if (!validateCaptcha(data?.captcha)) {
                    setError('captcha', { type: 'custom', message: 'Captcha does not match' });
                    return
                  }
                  if (!data?.privacypolicy) {
                    setError('captcha', { type: 'privacypolicy', message: 'required' });
                    return
                  }
                  dispatch({
                    type: `${LOAN_NUMBER}_GET`, payload: `loan/number/${data.mobile}/`, callback: (res) => {
                      if (res?.is_success) {
                        localStorage.setItem("temp-data", JSON.stringify(res.data))
                        navigate('/otp/')
                      } else {
                        navigate('/warning/')
                      }
                    }
                  });

                })}>
                  <RFG name="mobile" label="Enter your Registered Mobile Number" control={control} rules={{ required: true, maxLength: 10 }} className="mb-2">
                    <InputText autoComplete="false" />
                  </RFG>
                  <p className="notes">We will be sending OTP to the mobile number,
                    have it handy</p>
                  <div className="paragraph mt-1 mb-1">Verify You’re Human</div>
                  <label className="text-light mb-3 notes">
                    <LoadCanvasTemplate />
                  </label>
                  <RFG name="captcha" label="Enter captcha"
                    control={control}
                    rules={{ required: true, maxLength: 10 }}
                    className="mb-2">
                    <InputText autoComplete="false" />
                  </RFG>
                  <div className="mb-3">
                    Enter the captcha code in the black box.
                  </div>
                  <div className="mb-3">
                    <div className="notes d-flex gap-2">
                      <label htmlFor='pp' className="toggle-btn">
                        {checked ? <CheckSquare /> : <Square />}
                      </label>
                      <div>
                        By clicking on proceed, you have read and
                        agree to the
                        <span className='text-primary cursor-pointer' onClick={() => {
                          navigate('/termsconditions/', {
                            state: {
                              index: 0
                            }
                          })
                        }}> Privacy Policy,</span>
                        <span className='text-primary cursor-pointer' onClick={() => {
                          navigate('/termsconditions/', {
                            state: {
                              index: 1
                            }
                          })
                        }}> Disclaimer</span> and  
                        
                        <span className='text-primary cursor-pointer' onClick={() => {
                          navigate('/termsconditions/', {
                            state: {
                              index: 2
                            }
                          })
                        }}> Terms and Conditions</span> of Company.
                      </div>
                    </div>
                    <RFG name="privacypolicy" label="*"
                      control={control}
                      rules={{ required: true }} style={{ marginBottom: 0 }}>
                      <input id="pp" type="checkbox" style={{ display: 'none' }} onClick={(e) => { setChecked(e.target.checked) }} />
                    </RFG>
                  </div>
                  <div className="d-flex justify-content-center gap-4 mt-4">
                    <Button className="btn app-btn-danger px-4" onClick={() => { reset({}) }}>Cancel</Button>
                    <Button className="btn app-btn-primary px-4" type="submit">Proceed</Button>
                  </div>
                </form>
                <div className="error">{userState?.message}</div>

              </CardBody>
              <Footer name={main?.name} icon={main?.icon} />
            </Card>

          </Col>
        </Row>
      </Container>

    </>
  )
}