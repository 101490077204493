import React from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import RFG from "../../libs/inputs/RFG";
import { Card, CardBody, Button, Container, Col, Row, CardFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { LOGIN } from "../../services/actions";
import { Check } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
export default function OTP() {
  let dispatch = useDispatch()
  const navigate = useNavigate();
  let userState = useSelector((state) => state?.login)
  const { handleSubmit, control, setError } = useForm({
    defaultValues: { "otp": "" },
    mode: "all"
  })
  // update
  const [timer, setTimer] = React.useState(60); // Timer state
  const [resendDisabled, setResendDisabled] = React.useState(true); // Resend button state
  // 
  // update
  React.useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setResendDisabled(false);
    }
  }, [timer]);
  // 

  // Resend OTP handler
  const handleResendOTP = () => {
    setTimer(60);
    setResendDisabled(true);
    // Logic to resend OTP
    console.log("OTP resent");
  };
  return (
    <>

      <Container fluid className="g-0">
        <Row className="d-flex justify-content-center g-0">
          <Col xs={12} sm={8} md={5} xl={4}>
            <Card style={{ minWidth: 210, height: '100vh' }}>
              <CardBody className="px-4">
                <h1 className="mb-5 app-title">Payment Portal</h1>
                <form autoComplete="false" onSubmit={handleSubmit((data) => {
                  console.log("FORM", data)
                  // dispatch({ type: `${LOGIN}_AUTH`, payload: data });
                  console.log("OPT", data.otp == 1234)

                  if (data.otp == 1234) {
                    localStorage.setItem("data", localStorage.getItem("temp-data"))
                    localStorage.setItem("temp-data", null)
                    navigate('/dashboard/')
                  } else {
                    setError('otp', { type: 'custom', message: 'OTP invalid / expired' });
                  }

                })}>
                  <RFG name="otp" label="Enter the 6 Digit OTP Recieved" control={control} rules={{ required: true }} className="mb-2">
                    <InputText autoComplete="false" />
                  </RFG>
                  <Button className="btn btn-outline-info" type="button" onClick={handleResendOTP} disabled={resendDisabled}>
                    {resendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                  </Button>

                  <div className="d-flex justify-content-center gap-4 mt-3">
                    <Button className="btn btn-danger px-4" onClick={() => { navigate('/') }}>Cancel</Button>
                    <Button className="btn btn-primary px-4" type="submit">Proceed</Button>
                  </div>
                </form>
                <div className="error">{userState?.message}</div>

              </CardBody>
              <Footer />
            </Card>

          </Col>
        </Row>
      </Container>

    </>
  )
}