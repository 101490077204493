import React from 'react'
import {Routes, Route } from "react-router-dom";

export default function AnonyLayout(props) {
    let components = []
    props?.menus?.map((menu, j) => {
        components.push(menu)
        if (menu?.submenus) {
            menu?.submenus?.map((submenu, i) => {
                components.push(submenu)
            })
        }
    })

    return (
        <>
            <Routes>
                {
                    components?.map((menu, j) => <Route key={j} path={menu.to} element={menu.component} />)
                }
            </Routes>

        </>
    )
}