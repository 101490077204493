import React from 'react';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { FileSearch } from "@phosphor-icons/react";

export default function Filter({ header, list, }) {

    return (
        <>
            <div className="d-flex gap-3">
                <IconField iconPosition="left">
                    <InputText placeholder="Search" />
                </IconField>

                <IconField>
                    <InputIcon className="pi pi-spin pi-spinner"> </InputIcon>
                    <InputText />
                </IconField>
                <div className="d-flex justify-content-center gap-2">
                    <Calendar />
                    <Calendar />
                </div>
            </div>

        </>
    )
}