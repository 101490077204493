import React, { useRef } from 'react';
import { toBlob } from 'html-to-image';


  async function addBackgroundToImage(blob, name, backgroundColor = '#ffffff') {
    // Create an image element
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    return new Promise((resolve) => {
      img.onload = () => {
        // Create a canvas
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Fill the background with the chosen color
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the original image on top of the background
        context.drawImage(img, 0, 0);

        // Convert canvas to a Blob and resolve it
        canvas.toBlob((resultBlob) => {
          const fileWithBackground = new File(
            [resultBlob],
            `${name}.jpeg`,
            { type: blob.type }
          );
          resolve(fileWithBackground);
        }, blob.type);
      };
    });
  }

  export const handleShare = async (divRef, name) => {
    try {
      const blob = await toBlob(divRef.current);
      const fileWithBackground = await addBackgroundToImage(blob, name);
      // const file = new File([blob], `${name}.jpeg`, { type: blob.type });
      const data = {
        files: [fileWithBackground],
        title: 'Shared Reciept',
        text: 'Check out this image!',
      };

      if (navigator.canShare && navigator.canShare(data)) {
        await navigator.share(data);
      } else {
        console.error("Sharing not supported");
      }
    } catch (error) {
      console.error("Error sharing the image", error);
    }
  };


