const AUTH_DATA = "auth"
const LOGIN = "LOGIN"
// ---  Should  not update this code----i
export default function common(state = {}, action, MODULE) {
    // const DYNAMODULE = action?.type?.split("_").shift()
    // let reducer = DYNAMODULE.toLowerCase()
    let auth = {}
    switch (action.type) {
        case "LOGOUT_AUTH":
            localStorage.removeItem(AUTH_DATA)
            return {
                ...state,
                message: "",
                loading: false,
                auth: {}
            }
        case "LOGIN_IS_LOGGED":
            auth = localStorage.getItem(AUTH_DATA)
            return {
                ...state,
                message: "",
                loading: false,
                auth: auth ? JSON.parse(auth) : {}
            }
        case `${MODULE}_GET` || `${MODULE}_POST` ||
            `${MODULE}_PUT` || `${MODULE}_PATCH` || `${MODULE}_DELETE` || `${MODULE}_SEARCH`:
            return {
                ...state,
                loading: true,
            };
        case `${MODULE}_SUCCESS`:
            if (MODULE == LOGIN) {
                if (action.data != "IS_LOGGED") {
                    console.log("Auth Saved")
                    localStorage.setItem(AUTH_DATA, JSON.stringify(action.data))
                }
                return {
                    ...state,
                    loading: false,
                    success: true,
                    message: action?.message,
                    auth: JSON.parse(localStorage.getItem(AUTH_DATA))
                }
            }

            return {
                ...state,
                loading: false,
                success: true,
                message: action?.message,
                data: action.data
            };
        case `${MODULE}_FAILED`:
            return {
                ...state,
                loading: false,
                message: action?.message,
                success: false
            };
        default:
            auth = localStorage.getItem(AUTH_DATA)
            return { ...state, success: false, message: "", auth: auth ? JSON.parse(auth) : {}};
    }

}
// ---  Should  not update above code----