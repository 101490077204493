import React from 'react';
import { Badge } from 'primereact/badge';
import { Card, CardBody, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { ORDER } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from 'primereact/inputtext';
import RFG from '../../libs/inputs/RFG';
import { useForm } from 'react-hook-form';
import WebcamCapture from '../../libs/inputs/WebcamCapture';

export default function CheckInOut() {
    const dispatch = useDispatch()
    const [datas, setDatas] = React.useState({})
    const user = useSelector((state) => state?.loginState?.auth)
    const punch = useSelector((state) => state.punchState?.data)
    const punchState = useSelector((state) => state.punchState)
    const [time, setTime] = React.useState('00:00:00')
    const [timeStatus, setTimeStatus] = React.useState(0)
    const { handleSubmit, control, reset } = useForm({ defaultValues: { id: punch?.id, user: user?.id, location: "" }, mode: "all" })

    const timeDeff = (from_date, to_date, update = false) => {
        // Define two dates
        // const date1 = new Date("2024-11-10T10:00:00");
        // const date2 = new Date("2024-11-10T15:30:00");
        const date1 = new Date(from_date);
        // console.log("TO", to_date, "FROM", from_date)
        const date2 = update ? new Date() : new Date(to_date);

        // Get the difference in milliseconds
        const diffInMs = date2.getTime() - date1.getTime();

        // Convert milliseconds to hours, minutes, and seconds
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
        const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

        // console.log(`Difference: ${diffInHours} hours, ${diffInMinutes} minutes, and ${diffInSeconds} seconds`);
        return `${diffInHours}:${diffInMinutes}:${diffInSeconds}`
    }

    React.useEffect(() => {
        // dispatch({ type: `${PUNCH}_GET`, payload: user?.id });
    }, [])
    React.useEffect(() => {
        if (punch) {
            reset({ id: punch?.id, user: user?.id, location: "000,000", punch_status: punch.punch_status })
        }
    }, [punchState])

    function delayMicroseconds(microseconds) {
        return new Promise(resolve => setTimeout(resolve, microseconds / 1000));
    }
    React.useEffect(() => {
        let u = punch?.punch_status == "CHECKED_IN"
        if (u) {
            delayMicroseconds(10).then(() => {
                setTimeStatus(new Date().getTime())
            });
        }
    }, [timeStatus, punchState]);

    React.useEffect(() => {
        let u = punch?.punch_status == "CHECKED_IN"
        if (u) {
            let deff = timeDeff(punch?.created_at, punch?.modified_date, u)
            setTime(deff);
        }
    }, [timeStatus]);

    const onSubmitData = (data) => {
        console.log("Camera", data.id)
        if (!data['avatar'] || String(data['avatar']).startsWith("http")) {
            delete data['avatar']
        }
        // let action = data?.id ? `${PUNCH}_PATCH` : `${PUNCH}_POST`
        // data.punch_status = data?.punch_status == "CHECKED_IN" ? "CHECKED_OUT" : "CHECKED_IN"
        // console.log("Submiteed", data)
        // dispatch({ type: action, payload: data });
    }
    const camInit = (image) => {
        if (image) {
            let data = JSON.parse(localStorage.getItem("punch") || '{}')
            data.photo = image
            onSubmitData(data)
        }
        setDatas({})
    }
    const onFormSubmit = (data) => {
        localStorage.setItem("punch", JSON.stringify(data))
        setDatas(data)
    }
    // const capture = React.useCallback(
    //     () => {
    //         const imageSrc = webcamRef.current.getScreenshot();
    //     },
    //     [webcamRef]
    // );

    return (
        <>
            <WebcamCapture open={datas?.id} onChange={camInit} />
            <Container fluid className="py-3">
                <Row>
                    <Col sm={12} md={6}>
                        <Card>
                            <CardBody>
                                {punchState?.loading && <Spinner />}
                                <div className='text-center mb-5'>
                                    <h5 className='text-center mb-2 mt-2'>{user?.first_name}</h5>



                                    <Badge style={{ width: 100 }} value={time} size="small" severity="warning" />
                                </div>
                                <form onSubmit={handleSubmit(onFormSubmit)}>
                                    <input type="submit"
                                        value={punch?.punch_status == "CHECKED_IN" ? "CHECK OUT" : "CHECK IN"}
                                        className={'basic-btn ' + (punch?.punch_status == "CHECKED_IN" ? "checkedout" : "checkedin")} />
                                </form>
                                {punchState?.message}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}