import React from 'react';
import { ROLE, PERMISSION } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { ListBox } from 'primereact/listbox';
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import RFG from '../../libs/inputs/RFG';
import { InputText } from 'primereact/inputtext';
import { Cube, Pencil, Trash, Plus, Key, Ticket, CheckSquare, XSquare, ToggleLeft, Check, Cross, X } from "@phosphor-icons/react";
import { Dialog } from 'primereact/dialog';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { ToggleButton } from "primereact/togglebutton";
import Badge from 'react-bootstrap/Badge';
import Swal from 'sweetalert2';
import { AutoComplete } from "primereact/autocomplete";
import SearchableList from '../../libs/list/SearchableList';

export default function Role() {
    let dispatch = useDispatch()
    let roleState = useSelector((state) => state?.roleState)

    const [selectedRole, setSelectedRole] = React.useState()
    const [openDlg, setOpenDlg] = React.useState()
    const [openPemissionDlg, setOpenPemissionDlg] = React.useState([])

    const { handleSubmit, control, reset } = useForm({ defaultValues: {}, mode: "all" })

    React.useEffect(() => {
        dispatch({ type: `${ROLE}_GET`, payload: 0 });
    }, [])

    React.useEffect(() => {
        if (roleState?.data?.data?.results) {
            for (let role of roleState?.data?.data?.results) {
                if (role.name == selectedRole) {
                    formatData(role)
                    break
                }
            }
        }
    }, [roleState?.data?.data])

    const confirm2 = (event, data) => {
        Swal.fire({
            title: "Do you want to delete?",
            showDenyButton: true,
            confirmButtonText: "Cancel",
            denyButtonText: `Delete`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isDenied) {
                onDelete(data)

            }
        })
    };

    const onSubmit = (data) => {
        let action = data.id ? `${ROLE}_PATCH` : `${ROLE}_POST`
        dispatch({
            type: action, payload: data, callback: (resp) => {
                dispatch({ type: `${ROLE}_GET`, payload: 0 });
            }
        });
    }

    const onDelete = (data) => {
        let action = data.id ? `${ROLE}_DELETE` : `${ROLE}_POST`
        dispatch({
            type: action, payload: data.id, callback: (resp) => {
                dispatch({ type: `${ROLE}_GET`, payload: 0 });
            }
        });
    }

    const formatData = (role) => {
        setSelectedRole(role.name)
        let assigned = role._assigned
        let all = role?._permission ? Object.keys(role?._permission).map((key) => {

            let access = { role: role.name, value: key, name: key }
            for (let _access of role?._permission[key]) {
                let per = _access.codename.split("_")[0]
                access[per] = _access.codename
                access[`${per}_assigned`] = false
                if (assigned[key]) {
                    for (let _assigned of assigned[key]) {
                        console.log("access", access)
                        if (_access.codename == _assigned.codename) {
                            access[`${per}_assigned`] = true
                            break
                        }

                    }
                }
            }

            return access
        }) : []

        setOpenPemissionDlg(all)
        return all
    }

    const header = () => <>
        <hr></hr>
        <div className="d-flex gap-2 align-items-center flex-wrapper w-100 mt-2">
            <div className="flex-grow-1">Permissions</div>

            <div className="d-flex gap-3 align-items-center w-50">
                <div>Add</div>
                <div>View</div>
                <div>Edit</div>
                <div>Delete</div>
            </div>
        </div>
    </>

    const addPermission = (role, permission, action) => {
        let data = {
            id: 0,
            name: role,
            permissions: [permission],
            action: action ? "" : "remove"
        }
        dispatch({
            type: `${ROLE}_PATCH`, payload: data, callback: (resp) => {
                dispatch({ type: `${ROLE}_GET`, payload: 0 });
            }
        });
    }

    const itemTemplate = (option, buttons) => {
        return (
            <div className="d-flex gap-2 align-items-center flex-wrapper w-100 p-1">

                <div className="flex-grow-1 d-flex gap-2 align-items-center">
                    <Cube fontSize={20} color="blue" weight='fill' />
                    {option.name}
                </div>
                <div className="d-flex gap-4 align-items-center w-50">
                    <Badge bg={option.add_assigned ? 'success' : 'danger'}
                        onClick={(e) => {
                            addPermission(option.role, option.add, !option.add_assigned)
                        }}
                    >
                        {option.add_assigned ? <Check /> : <X />}
                    </Badge>

                    <Badge bg={option.view_assigned ? 'success' : 'danger'}
                        onClick={(e) => {
                            addPermission(option.role, option.view, !option.view_assigned)
                        }}
                    >
                        {option.view_assigned ? <Check /> : <X />}
                    </Badge>

                    <Badge bg={option.change_assigned ? 'success' : 'danger'}
                        onClick={(e) => {
                            addPermission(option.role, option.change, !option.change_assigned)
                        }}
                    >
                        {option.change_assigned ? <Check /> : <X />}
                    </Badge>

                    <Badge bg={option.delete_assigned ? 'success' : 'danger'}
                        onClick={(e) => {
                            addPermission(option.role, option.delete, !option.delete_assigned)
                        }}
                    >
                        {option.delete_assigned ? <Check /> : <X />}
                    </Badge>

                </div>
            </div>
        );
    };

    return (
        <>
            <Dialog header={"Assign Permission to " + selectedRole} style={{ width: '50vw', maxWidth: "400px", marginLeft: 20, marginRight: 20 }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                visible={Boolean(openPemissionDlg.length != 0)} onHide={() => { if (!openPemissionDlg) return; setOpenPemissionDlg([]); }}>
                <SearchableList
                    onClick={(i, data) => { if (i == 0) confirm2(i, data); if (i == 1) setOpenPemissionDlg(data) }}
                    optionLabel="name"
                    list={openPemissionDlg}
                    header={header()}
                    onRowRender={itemTemplate}
                />
            </Dialog>

            <Dialog header="Add New Role" style={{ width: '50vw', maxWidth: "400px", marginLeft: 20, marginRight: 20 }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                visible={openDlg} onHide={() => { if (!openDlg) return; setOpenDlg(false); }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="pt-2 pb-3">
                        <RFG name="id" control={control}>
                            <input type="hidden" />
                        </RFG>
                        <RFG name="name" label="Role" control={control} rules={{ required: true }} className="mb-2 p-inputtext-sm">
                            <InputText />
                        </RFG>
                    </div>
                    <div className="pt-2 pb-3">
                        <Button type="submit" className={'basic-btn'} size='md' >Submit</Button>
                    </div>

                </form>
            </Dialog>

            <Container fluid>
                <Row>
                    <Col xs={12} sm={10} md={6} className="my-3">
                        <SearchableList
                            onClick={(i, data) => { if (i == 0) confirm2(i, data); if (i == 1) formatData(data) }}
                            buttons={[
                                <Trash fontSize={20} color="red" />,
                                <Key fontSize={20} color="orange" />
                            ]}
                            headerButtons={[<Plus fontSize={20} color="orange" onClick={() => { setOpenDlg(true) }} />]}
                            optionLabel="name"
                            list={roleState?.data?.data?.results}
                        />
                    </Col>
                </Row>
            </Container>

        </>
    )
}